import axios, { AxiosError } from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { UserManagementBaseResponse } from 'api/interfaces';
import { ICompanyState } from 'store/company/interfaces';

export const getCompanyInfo = createAsyncThunk<ICompanyState, string>(
  'company/getCompanyInfo',
  async (id, { rejectWithValue }) => {
    const endpoint =
      process.env.REACT_APP_PIMCORE_ENDPOINT + `api/companies/sp-settings/identifiers/${id}`;

    try {
      const { data } = await axios.get(endpoint);

      return data[0];
    } catch (e) {
      const error: AxiosError<UserManagementBaseResponse> =
        e as AxiosError<UserManagementBaseResponse>;
      return rejectWithValue(error.response?.data);
    }
  },
);
