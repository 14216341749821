import {
  differenceInCalendarMonths,
  isAfter,
  startOfDay,
  startOfToday,
  startOfTomorrow,
} from 'date-fns';
import { ICompanyState } from 'store/company/interfaces';
import moment from 'moment';
import { defineMessages } from 'react-intl';

export const getBirthDate = (border: string) => {
  const today = new Date(new Date().setHours(0, 0, 0, 0));
  if (border === 'min')
    return new Date(today.getFullYear() - 120, today.getMonth(), today.getUTCDate());
  else if (border === 'max')
    return new Date(today.getFullYear() - 10, today.getMonth(), today.getUTCDate());
  else return new Date();
};

export const getDateByKey = (key: string) => {
  let keyDate = new Date(key);
  if (isNaN(keyDate.getTime())) {
    keyDate = startOfDay(new Date(`${key.split('-')[1]}-${key.split('-')[0]}-01`));
  }
  return keyDate;
};

export const getMinStartDate = (company: ICompanyState | null, endDate?: Date | null | string) => {
  if (endDate) {
    const temDate = new Date(endDate);
    //I hardcoded this value because this code needs just for the profit company
    //and just for some special use case, we definitely need to choose the first day of the month
    return new Date(temDate.getFullYear(), temDate.getMonth(), 1);
  }
  const today = startOfToday();
  if (company === null) return today;

  let minimumDate = new Date(today.getFullYear(), today.getMonth() + 1, 1);

  let startDate = today;
  if (Object.keys(company.fees).length > 0) {
    startDate = getDateByKey(Object.keys(company.fees)[0]);
  }
  /*
    based on our API response - we can't define a real start date for flexible contract, 
    because fees array have only month and year information about start contract, 
    but in this comment https://hansefit.atlassian.net/browse/FE-1181?focusedCommentId=57330
    we have explanation that start of contract shoud be set only at the beginning of the month for real customers,
    so if contract is flexible and start month equals current month user could be registered to beginning of tomorrow
  */
  if (company.regularStartDate === 'flexible') {
    if (
      startDate.getFullYear() <= today.getFullYear() &&
      startDate.getMonth() <= today.getMonth()
    ) {
      minimumDate = startOfTomorrow();
    }
  }
  if (minimumDate < startDate) {
    minimumDate = startDate;
  }

  if (company.registrationDeadline) {
    const registrationDay = Number(company.registrationDeadline);
    const registrationDeadline = new Date(today.getFullYear(), today.getMonth(), registrationDay);
    //check if the registration day has not passed yet in this month for 1_st_month contract
    const isSingleContractDeadlinePassed =
      company.regularStartDate === '1st_of_month' &&
      isAfter(today, registrationDeadline) &&
      differenceInCalendarMonths(minimumDate, registrationDeadline) === 1;
    const isFlexibleContractDeadlinePassed =
      company.regularStartDate === 'flexible' &&
      minimumDate.getMonth() === today.getMonth() &&
      isAfter(today, registrationDeadline);
    if (isSingleContractDeadlinePassed || isFlexibleContractDeadlinePassed) {
      return new Date(minimumDate.getFullYear(), minimumDate.getMonth() + 1, 1);
    }
  }
  return minimumDate;
};
export const getMaxStartDate = (company: ICompanyState | null) => {
  const today = startOfToday();
  if (company === null) return today;

  const monthLimit = 6;
  const firstDate = new Date(today.getFullYear(), today.getMonth(), 1);
  const nextSixMonth = moment(firstDate).add(monthLimit, 'M').toDate();
  const feesKeys = Object.keys(company.fees);
  if (feesKeys.length > 0) {
    const date = new Date(feesKeys[feesKeys.length - 1]);
    return date < nextSixMonth ? date : nextSixMonth;
  } else {
    return nextSixMonth;
  }
};

export const monthMessages: Record<string, { id: string; defaultMessage: string }> = defineMessages(
  {
    0: {
      id: 'month_picker.january',
      defaultMessage: 'January',
    },
    1: {
      id: 'month_picker.february',
      defaultMessage: 'February',
    },
    2: {
      id: 'month_picker.march',
      defaultMessage: 'March',
    },
    3: {
      id: 'month_picker.april',
      defaultMessage: 'April',
    },
    4: {
      id: 'month_picker.may',
      defaultMessage: 'May',
    },
    5: {
      id: 'month_picker.june',
      defaultMessage: 'June',
    },
    6: {
      id: 'month_picker.july',
      defaultMessage: 'July',
    },
    7: {
      id: 'month_picker.august',
      defaultMessage: 'August',
    },
    8: {
      id: 'month_picker.september',
      defaultMessage: 'September',
    },
    9: {
      id: 'month_picker.october',
      defaultMessage: 'October',
    },
    10: {
      id: 'month_picker.november',
      defaultMessage: 'November',
    },
    11: {
      id: 'month_picker.december',
      defaultMessage: 'December',
    },
  },
);
