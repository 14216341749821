import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { ICompaniesState } from 'store/company/interfaces';
import { FetchingStatus } from 'store/interfaces';
import { getCompanyInfo } from './getCompanyInfo';
import { globalCustomFieldsSort } from 'utils/hardcodedData';

export const getCompanyExtraReducer = (builder: ActionReducerMapBuilder<ICompaniesState>) => {
  builder.addCase(getCompanyInfo.pending, (state, action) => {
    state.fetchingStatus = FetchingStatus.PENDING;
    state.error = null;
    state.errorViolations = null;
  });
  builder.addCase(getCompanyInfo.fulfilled, (state, action) => {
    if (action.payload) {
      state.data = action.payload;
      if (state.data?.globalCustomFields) {
        state.data.globalCustomFields = action.payload.globalCustomFields.filter(
          item => globalCustomFieldsSort.filter(gcf => gcf.includes(item.key)).length > 0,
        );
      }
      state.fetchingStatus = FetchingStatus.FULFILLED;
    } else {
      state.error = { message: 'Company not exist' };
    }
  });

  builder.addCase(getCompanyInfo.rejected, (state, action) => {
    state.error = action.error;
    state.fetchingStatus = FetchingStatus.REJECTED;
  });
};
